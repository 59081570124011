<template>
  <div>
    <Echart :options="options" id="centreLeft1Chart" height="180px" width="400px"></Echart>
  </div>
</template>

<script>
import Echart from '@/common/echart'
export default {
  data() {
    return {
      options: {},
    };
  },
  components: {
    Echart,
  },
  props: {
    cdata: {
      type: Object,
      default: () => ({})
    },
  },
  watch: {
    cdata: {
      handler(newData) {
        this.options = {
          legend: {
            right: 0
          },
          tooltip: {},
          dataset: {
            dimensions: newData.dimensions,
            source: newData.source
          },
          xAxis: {
            type: 'category',
          },
          yAxis: {
            name: '单位',
            splitLine: {    // gird 区域中的分割线
              show: true,   // 是否显示
              lineStyle: {
                color: 'rgba(143, 171, 191, 1)',
                width: 0.7,
                type: 'solid'   // dashed
              }
            },
            axisLine: {
              show: false
            },
            type: 'value',
            scale: true,
            max: 300,
            min: 0,
            splitNumber: 3,
            boundaryGap: [0.2, 0.2]
          },
          series: [
            {
              type: 'bar',
              barWidth: 10,
              color: {
                type: 'linear',
                x: 0, //右
                y: 0, //下
                x2: 0, //左
                y2: 1, //上
                colorStops: [
                  {
                    offset: 0,
                    color: 'rgba(122, 163, 204, 1)' // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: 'rgba(96, 112, 128, 0.1)' // 100% 处的颜色
                  }
                ]
              }
            },
            {
              type: 'bar',
              barWidth: 10,
              color: {
                type: 'linear',
                x: 0, //右
                y: 0, //下
                x2: 0, //左
                y2: 1, //上
                colorStops: [
                  {
                    offset: 0,
                    color: 'rgba(51, 204, 204, 1)' // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: 'rgba(31, 89, 89, 0.25)' // 100% 处的颜色
                  }
                ]
              }
            },

          ]
        }
      },
      immediate: true,
      deep: true
    }
  }
};
</script>

<style lang="scss" scoped></style>