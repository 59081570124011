<template>
  <div class="bigbox">
    <Chart :cdata="cdata" />
  </div>
</template>

<script>
import Chart from './chart.vue';
export default {
  data() {
    return {
      cdata: {
        dimensions: ['product', '总信息量', '总匹配量',],
        source: [
          { product: '供应', 总信息量: 280, 总匹配量: 210,},
          { product: '采购', 总信息量: 290, 总匹配量: 240,},
          { product: '劳务', 总信息量: 230, 总匹配量: 180,},
          { product: '服务', 总信息量: 250, 总匹配量: 190,}
        ]
      }
    }
  },
  components: {
    Chart,
  },
  mounted() {
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
  // .bigbox{
  //   margin-top: 10px;
  // }
</style>