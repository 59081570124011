<template>
  <div id="centerRight1">
    <div class="title">
      <span class="text">农产品</span>
    </div>
    <div class="content">
      <table class="mb-5" cellspaing="0" align="center" width="100%">
        <thead>
          <tr>
            <th>上市时间</th>
            <th>品类</th>
            <th>种植/公斤</th>
            <th>位置</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in data" :key="index">
            <td>{{ item.time }}</td>
            <td>
              {{ item.text }}
            </td>
            <td>
              {{ item.num }}
            </td>
            <td>
              {{ item.spend }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: [
        {
          time: '2月',
          text: '小米',
          num: '534',
          spend: '杨凌'
        },
        {
          time: '3月',
          text: '生菜',
          num: '245',
          spend: '咸阳'
        },
        {
          time: '4月',
          text: '豌豆',
          num: '365',
          spend: '渭南'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
// $box-height: 410px;
// $box-width: 300px;
#centerRight1 {
  width: 400px;
  margin-top: 10px;

  .title {
    width: 100%;
    height: 32px;
    background-image: url('../../assets/img/title.png');
    background-size: cover;
    display: flex;
    align-items: center;

    .text {
      margin-left: 38px;
      font-size: 16px;
      font-weight: 700;
      letter-spacing: 1.78px;
    }
  }

  .content {
    margin-top: 10px;
  }

  .table-img {
    width: 65px;
    height: 40px;
    border-radius: 8px;
  }

  th {
    padding: 5px 0;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 2px;
    color: rgba(51, 204, 204, 1);
  }

  td {
    text-align: center;
    padding: 6px 13px;
    font-size: 14px;
    font-weight: 700;
    color: rgba(255, 255, 255, 1);
  }
}
</style>
