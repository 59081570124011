<template>
  <div id="centerRight1">
    <!-- <div class="bg-color-black">
      <div class="d-flex pt-2 pl-2">
        <span>
          <icon name="chart-line" class="text-icon"></icon>
        </span>
        <div class="d-flex">
          <span class="fs-xl text mx-2">任务完成排行榜</span>
        </div>
      </div>
      <div class="d-flex jc-center body-box">
        <dv-scroll-board class="dv-scr-board" :config="config" />
      </div> 
    </div> -->
    <div class="title">
      <span class="text">用户收益</span>
    </div>
    <div class="content">
      <div class="item" v-for="(item, index) in incomeData" :key="index">
        <img :src="item.incomeUrl" alt="" class="item-img">
        <div class="text-box">
          <div class="text">{{ item.text }}</div>
          <div class="number">
            <span class="num-content">{{ item.number | numberToCurrency }}</span>
            <span class="num-unit">{{ item.unit }}</span>
          </div>
        </div>
      </div>
    </div>
    <centerRightChart/>
  </div>
</template>

<script>
import centerRightChart from '@/components/echart/centerRight/centerRightChart'
export default {
  data() {
    return {
      incomeData: [
        {
          incomeUrl: 'https://nonglian.oss-cn-shanghai.aliyuncs.com/income-icon1.png',
          text: '总收益',
          number: 1926,
          unit: '万元'
        },
        {
          incomeUrl: 'https://nonglian.oss-cn-shanghai.aliyuncs.com/income-icon2.png',
          text: '收益项目',
          number: 48,
          unit: '个'
        }
      ]
    }
  },
  components:{
    centerRightChart
  }
}
</script>

<style lang="scss" scoped>
// $box-height: 410px;
// $box-width: 300px;
#centerRight1 {
  width: 400px;
  margin-top: 10px;

  .title {
    width: 100%;
    height: 32px;
    background-image: url('../../assets/img/title.png');
    background-size: cover;
    display: flex;
    align-items: center;

    .text {
      margin-left: 38px;
      font-size: 16px;
      font-weight: 700;
    }
  }

  .content {
    width: 100%;
    display: flex;
    margin-bottom: 20px;
  }

  .item {
    width: 50%;
    display: flex;
    margin-top: 6px;

    .item-img {
      width: 40px;
      height: 40px;
      margin-right: 11px;
    }

    .text {
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 1.25px;
      color: rgba(255, 255, 255, 1);
    }

    .num-content {
      font-size: 18px;
      font-weight: 700;
      letter-spacing: 1.88px;
      margin-right: 4px;
    }

    .num-unit {
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 1.25px;
      color: rgba(255, 255, 255, 1);
      opacity: 0.5;
    }
  }
}
</style>
