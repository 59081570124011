<template>
  <div id="index" ref="appRef">
    <div class="bg">
      <dv-loading v-if="loading">Loading...</dv-loading>
      <div v-else class="host-body">
        <!-- 标题行 -->
        <div class="title">
          <img src="../assets/img/logo.png" alt="" class="big-title-img">
          <div class="time">
            <!-- <span class="year">{{ dateYear }}</span>
            <span class="week">{{ dateWeek }}</span>
            <span class="day">{{ dateDay }}</span> -->
          </div>
        </div>
        <!-- 内容 -->
        <div class="content">
          <!-- 左边 -->
          <div class="left">
            <centerLeft2 />
            <centerLeft1 />
            <!-- <centerLeft3 /> -->
          </div>
          <!-- 中间 -->
          <div class="center">
            <!-- <div class="dataAnalysis-box">
              <dv-border-box-6 class="dataAnalysis">农业机械智能化</dv-border-box-6>
              <img src="https://nonglian.oss-cn-shanghai.aliyuncs.com/center-icon1.png" alt="" class="dataAnalysis-img">
            </div>
            <div class="dataAnalysis-box2">
              <dv-border-box-6 class="dataAnalysis">农产品质量追溯</dv-border-box-6>
              <img src="https://nonglian.oss-cn-shanghai.aliyuncs.com/center-icon1.png" alt="" class="dataAnalysis-img">
            </div>
            <div class="dataAnalysis-box3">
              <dv-border-box-6 class="dataAnalysis">农业大数据分析</dv-border-box-6>
              <img src="https://nonglian.oss-cn-shanghai.aliyuncs.com/center-icon1.png" alt="" class="dataAnalysis-img">
            </div>
            <div class="dataAnalysis-box4">
              <dv-border-box-6 class="dataAnalysis">农产品营销</dv-border-box-6>
              <img src="https://nonglian.oss-cn-shanghai.aliyuncs.com/center-icon1.png" alt="" class="dataAnalysis-img">
            </div>
            <img src='https://nonglian.oss-cn-shanghai.aliyuncs.com/center-icon2.png' alt='' class="center-img"> -->
            <!-- <img src="../assets/center-icon1.png" alt="" class="centerBottom-img"> -->
            <div class="center-top">
              <img src="../assets/img/location.png" alt="" class="top-img">
              <div class="location-content">
                <span class="location-title">当前位置：</span>
                <span class="location-text">陕西省</span>
              </div>
            </div>
            <div class="userTotal-box">
              <div class="total-item" v-for="(item, index) in totalData" :key="index">
                <img src="https://nonglian.oss-cn-shanghai.aliyuncs.com/home-center-icon1.png" alt="" class="total-icon">
                <div class="total-content">
                  <div class="total-text">{{ item.text }}</div>
                  <div class="totalNum-content">
                    <span class="total-num" v-change="{ eUnit: '' }">{{ item.num }}</span>
                    <span class="total-unit">{{ item.unit }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="map">
              <div class="map1">
                <div class="lable-box">
                  <div class="up-line up1"></div>
                  <div class="lable locate1">
                    <div class="name">新疆</div>
                    <div class="num" v-change="{ eUnit: '' }">4500</div>
                    <img src="../assets/img/right-center-icon11.png" alt="" class="lable-icon">
                  </div>
                  <div class="down-line item1"></div>
                  <div class="map-round-box">
                    <div class="round"></div>
                    <div class="dian"></div>
                  </div>
                </div>
                <div class="lable-box">
                  <div class="up-line up2"></div>
                  <div class="lable locate2">
                    <div class="name">宁夏</div>
                    <div class="num" v-change="{ eUnit: '' }">9340</div>
                    <img src="../assets/img/right-center-icon11.png" alt="" class="lable-icon">
                  </div>
                  <div class="down-line item2"></div>
                  <div class="map-round-box1">
                    <div class="round"></div>
                    <div class="dian"></div>
                  </div>
                </div>
                <div class="lable-box">
                  <div class="up-line up3"></div>
                  <div class="lable locate3">
                    <div class="name">甘肃</div>
                    <div class="num" v-change="{ eUnit: '' }">8170</div>
                    <img src="../assets/img/right-center-icon11.png" alt="" class="lable-icon">
                  </div>
                  <div class="down-line item3"></div>
                  <div class="map-round-box2">
                    <div class="round"></div>
                    <div class="dian"></div>
                  </div>
                </div>
                <div class="lable-box">
                  <div class="up-line up4"></div>
                  <div class="lable locate4">
                    <div class="name">云南</div>
                    <div class="num" v-change="{ eUnit: '' }">3180</div>
                    <img src="../assets/img/right-center-icon11.png" alt="" class="lable-icon">
                  </div>
                  <div class="down-line item4"></div>
                  <div class="map-round-box3">
                    <div class="round"></div>
                    <div class="dian"></div>
                  </div>
                </div>
                <div class="lable-box">
                  <div class="up-line up5"></div>
                  <div class="lable locate5">
                    <div class="name">江西</div>
                    <div class="num" v-change="{ eUnit: '' }">2980</div>
                    <img src="../assets/img/right-center-icon11.png" alt="" class="lable-icon">
                  </div>
                  <div class="down-line item5"></div>
                  <div class="map-round-box4">
                    <div class="round"></div>
                    <div class="dian"></div>
                  </div>
                </div>
                <div class="lable-box">
                  <div class="avatar-box">
                    <img src="../assets/img/avatar.png" alt="">
                    <img src="../assets/img/avatar.png" alt="">
                    <img src="../assets/img/avatar.png" alt="">
                  </div>
                  <div class="up-line up6"></div>
                  <div class="lable locate6">
                    <div class="name">陕西</div>
                    <div class="num" v-change="{ eUnit: '' }">23830</div>
                    <img src="../assets/img/map-icon11.png" alt="" class="lable-icon">
                  </div>
                  <div class="down-line item6"></div>
                  <div class="map-round-box5">
                    <div class="round"></div>
                    <div class="dian"></div>
                  </div>
                </div>
                <div class="button-box">
                  <router-link to="/page3">
              <button @mouseenter="handleMouseEnter3" @mouseleave="handleMouseLeave3" >
                <img src="https://nonglian.oss-cn-shanghai.aliyuncs.com/center-bottom-icon3.png" alt=""
                  class="button-img">
                <div class="button-text" ref="button3">数字农管</div>
                <img src="../assets/img/button-icon1.png" alt="" class="button-icon" v-if="showButtonIcon3">
              </button>
            </router-link>
              <router-link to="/page2">
                <button @mouseenter="handleMouseEnter" @mouseleave="handleMouseLeave">
                  <img src="https://nonglian.oss-cn-shanghai.aliyuncs.com/center-bottom-icon1.png" alt=""
                    class="button-img">
                  <div class="button-text" ref="button1">数字农人</div>
                  <img src="../assets/img/button-icon1.png" alt="" class="button-icon" v-if="showButtonIcon1">
                </button>
              </router-link>
              <router-link to="/page4">
              <button @mouseenter="handleMouseEnter2" @mouseleave="handleMouseLeave2" >
                <img src="https://nonglian.oss-cn-shanghai.aliyuncs.com/center-bottom-icon2.png" alt=""
                  class="button-img">
                <div class="button-text" ref="button2">数字营销</div>
                <img src="../assets/img/button-icon1.png" alt="" class="button-icon" v-if="showButtonIcon2">
              </button>
            </router-link>
            </div>
              </div>
            </div>
            <!-- <chinaMap/> -->
          </div>
          <!-- 右边 -->
          <div class="right">
            <centerRight1 />
            <centerRight2 />
            <centerRight3 />
          </div>
        </div>
        <div class="bottom">
          <div class="bottom-left">
            <dv-decoration-2 class="decoration-two1" />
            <img src="../assets/img/bottom-icon2.png" alt="" class="bottom-img">
          </div>
          <div class="bottom-center">
            <div class="bottom-center-bg"></div>
            <div class="bottom-text">
              <!-- <img src="../assets/beian.png" alt="" class="beian-img"> -->
              <a href="http://yanglingnongfu.com/#/" target="_blank">yanglingnongfu.com</a> 版权所有 备案号：<a href="https://beian.miit.gov.cn/" target="_blank">陕ICP备19000833号-3</a> <img class="beian-img" src="https://nonglian.oss-cn-shanghai.aliyuncs.com/beian.png" alt=""><a href="https://beian.mps.gov.cn/#/query/webSearch?code=61110402000163\" rel="noreferrer" target="_blank">陕公网安备61110402000163</a> 技术支持：西安农链互联网科技有限公司
            </div>
            
            <div class="bottom-center-bg"></div>
          </div>
          <div class="bottom-right">
            <img src="../assets/img/bottom-icon1.png" alt="" class="bottom-img">
            <dv-decoration-2 class="decoration-two2" />
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import drawMixin from "../utils/drawMixin";
import { formatTime } from '../utils/index.js'
import centerLeft1 from './page1/centerLeft1'
import centerLeft2 from './page1/centerLeft2'
// import centerLeft3 from './page1/centerLeft3'
import centerRight1 from './page1/centerRight1'
import centerRight2 from './page1/centerRight2'
import centerRight3 from './page1/centerRight3'
// import chinaMap from './page1/chinaMap'

export default {
  mixins: [drawMixin],
  data() {
    return {
      timing: null,
      loading: true,
      dateDay: null,
      dateYear: null,
      dateWeek: null,
      weekday: ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'],
      decorationColor: ['#568aea', '#000000'],
      showButtonIcon1: false,
      showButtonIcon2: false,
      showButtonIcon3: false,
      totalData: [
        {
          text: '总用户数',
          num: 52000,
          unit: '人'
        },
        {
          text: '专家',
          num: 5900,
          unit: '人'
        },
        {
          text: '采购商',
          num: 4500,
          unit: '人'
        }, {
          text: '农服队',
          num: 200,
          unit: '人'
        },
      ]
    }
  },
  components: {
    centerLeft1,
    centerLeft2,
    // centerLeft3,
    centerRight1,
    centerRight2,
    centerRight3,
    // chinaMap
  },
  mounted() {
    // this.timeFn()
    this.cancelLoading()
  },
  beforeDestroy() {
    clearInterval(this.timing)
  },
  methods: {
    handleMouseEnter() {
      this.showButtonIcon1 = true
      this.$refs.button1.style.color = 'rgba(255, 255, 255, 1)'
    },
    handleMouseLeave() {
      this.showButtonIcon1 = false
      this.$refs.button1.style.color = 'rgba(217, 231, 255, 0.5)'
    },
    handleMouseEnter2() {
      this.showButtonIcon2 = true
      this.$refs.button2.style.color = 'rgba(255, 255, 255, 1)'
    },
    handleMouseLeave2() {
      this.showButtonIcon2 = false
      this.$refs.button2.style.color = 'rgba(217, 231, 255, 0.5)'
    },
    handleMouseEnter3() {
      this.showButtonIcon3 = true
      this.$refs.button3.style.color = 'rgba(255, 255, 255, 1)'
    },
    handleMouseLeave3() {
      this.showButtonIcon3 = false
      this.$refs.button3.style.color = 'rgba(217, 231, 255, 0.5)'
    },
    timeFn() {
      this.timing = setInterval(() => {
        this.dateDay = formatTime(new Date(), 'HH: mm: ss')
        this.dateYear = formatTime(new Date(), 'yyyy.MM.dd')
        this.dateWeek = this.weekday[new Date().getDay()]
      }, 1000)
    },
    cancelLoading() {
      setTimeout(() => {
        this.loading = false
      }, 500)
    },
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/scss/index.scss';
</style>
