<template>
  <div class="bigbox">
    <Chart :cdata="cdata" />
  </div>
</template>

<script>
import Chart from './chart.vue';
export default {
  data () {
    return {
      cdata: {
        indicatorData:['1.1', '2.1', '3.1', '4.1', '5.1', '6.1'],
        seriesData1: [19,23,18,29,19,30],
        seriesData2: [19,12,18,15,35,19],
        seriesData3: [38,25,34,30,40,32],
      }
    }
  },
  components: {
    Chart,
  }
}
</script>
<style lang="scss" scoped>
  .bigbox{
    margin-top: -10px;
  }
</style>