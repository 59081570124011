<template>
  <div id="centerLeft1">
    <div class="title">
      <span class="text">产业服务人群</span>
    </div>
    <div class="content">
      <div class="item" v-for="(item, index) in data" :key="index">
        <div class="item-left">
          <img :src="item.url" alt="" class="item-img">
          <span class="item-text">{{ item.text }}</span>
        </div>
        <div class="item-right">
          <span class="item-num" v-change="{ eUnit: '' }">{{ item.num }}</span>
          <span class="item-unit">{{ item.unit }}</span>
        </div>
      </div>
    </div>
    <CenterLeft1Chart />
  </div>
</template>

<script>
import CenterLeft1Chart from '@/components/echart/centerLeft/centerLeft1Chart'
export default {
  data() {
    return {
      data: [
        {
          num: 96,
          unit: '人',
          text: '农业专家',
          url: 'https://nonglian.oss-cn-shanghai.aliyuncs.com/page1-centerLeft3-icon1.png'
        },
        {
          num: 120,
          unit: '人',
          text: '专业农服队',
          url: 'https://nonglian.oss-cn-shanghai.aliyuncs.com/page1-centerLeft3-icon2.png'
        },
        {
          num: 70,
          unit: '人',
          text: '农贸服务商',
          url: 'https://nonglian.oss-cn-shanghai.aliyuncs.com/page1-centerLeft3-icon3.png'
        },
        {
          num: 82,
          unit: '人',
          text: '农业技术人员',
          url: 'https://nonglian.oss-cn-shanghai.aliyuncs.com/page1-centerLeft3-icon4.png'
        },
      ],
    }
  },
  components: {
    CenterLeft1Chart
  },
  mounted() {

  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
#centerLeft1 {
  width: 400px;

  .title {
    width: 100%;
    height: 32px;
    background-image: url('../../assets/img/title.png');
    background-size: cover;
    display: flex;
    align-items: center;
    margin-bottom: 22px;

    .text {
      margin-left: 38px;
      font-size: 16px;
      font-weight: 700;
      letter-spacing: 1.78px;
    }
  }

  .content {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 23px;

    .item {
      width: 188px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-right: 20px;

      &:nth-child(2n) {
        margin-right: 0;
      }

      &:nth-child(1) {
        margin-bottom: 11px;
      }

      &:nth-child(2) {
        margin-bottom: 11px;
      }

      .item-left,
      .item-right {
        display: flex;
        align-items: center;
      }

      .item-text {
        font-size: 12px;
        font-weight: 400;
        color: rgba(217, 231, 255, 1);
        margin-left: 10px;
      }

      .item-num {
        font-size: 20px;
        font-weight: 700;
        letter-spacing: 2px;
        color: rgba(79, 240, 255, 1);
      }

      .item-unit {
        font-size: 12px;
        font-weight: 400;
        color: rgba(217, 231, 255, 1);
        margin-left: 4px;
      }
    }
  }
}</style>
